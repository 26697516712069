// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-alternatives-jsx": () => import("./../../../src/pages/alternatives.jsx" /* webpackChunkName: "component---src-pages-alternatives-jsx" */),
  "component---src-pages-android-jsx": () => import("./../../../src/pages/android.jsx" /* webpackChunkName: "component---src-pages-android-jsx" */),
  "component---src-pages-app-purchase-thank-you-jsx": () => import("./../../../src/pages/app-purchase-thank-you.jsx" /* webpackChunkName: "component---src-pages-app-purchase-thank-you-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-blog-thank-you-jsx": () => import("./../../../src/pages/blog-thank-you.jsx" /* webpackChunkName: "component---src-pages-blog-thank-you-jsx" */),
  "component---src-pages-charles-alternative-jsx": () => import("./../../../src/pages/charles-alternative.jsx" /* webpackChunkName: "component---src-pages-charles-alternative-jsx" */),
  "component---src-pages-chrome-devtools-alternative-jsx": () => import("./../../../src/pages/chrome-devtools-alternative.jsx" /* webpackChunkName: "component---src-pages-chrome-devtools-alternative-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-docker-jsx": () => import("./../../../src/pages/docker.jsx" /* webpackChunkName: "component---src-pages-docker-jsx" */),
  "component---src-pages-docs-jsx": () => import("./../../../src/pages/docs.jsx" /* webpackChunkName: "component---src-pages-docs-jsx" */),
  "component---src-pages-electron-jsx": () => import("./../../../src/pages/electron.jsx" /* webpackChunkName: "component---src-pages-electron-jsx" */),
  "component---src-pages-fiddler-alternative-jsx": () => import("./../../../src/pages/fiddler-alternative.jsx" /* webpackChunkName: "component---src-pages-fiddler-alternative-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-java-jsx": () => import("./../../../src/pages/java.jsx" /* webpackChunkName: "component---src-pages-java-jsx" */),
  "component---src-pages-javascript-jsx": () => import("./../../../src/pages/javascript.jsx" /* webpackChunkName: "component---src-pages-javascript-jsx" */),
  "component---src-pages-keep-me-updated-jsx": () => import("./../../../src/pages/keep-me-updated.jsx" /* webpackChunkName: "component---src-pages-keep-me-updated-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-python-jsx": () => import("./../../../src/pages/python.jsx" /* webpackChunkName: "component---src-pages-python-jsx" */),
  "component---src-pages-ruby-jsx": () => import("./../../../src/pages/ruby.jsx" /* webpackChunkName: "component---src-pages-ruby-jsx" */),
  "component---src-pages-sent-to-computer-jsx": () => import("./../../../src/pages/sent-to-computer.jsx" /* webpackChunkName: "component---src-pages-sent-to-computer-jsx" */),
  "component---src-pages-terms-of-service-jsx": () => import("./../../../src/pages/terms-of-service.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-pages-web-purchase-thank-you-jsx": () => import("./../../../src/pages/web-purchase-thank-you.jsx" /* webpackChunkName: "component---src-pages-web-purchase-thank-you-jsx" */),
  "component---src-pages-will-it-cors-jsx": () => import("./../../../src/pages/will-it-cors.jsx" /* webpackChunkName: "component---src-pages-will-it-cors-jsx" */),
  "component---src-templates-doc-jsx": () => import("./../../../src/templates/doc.jsx" /* webpackChunkName: "component---src-templates-doc-jsx" */),
  "component---src-templates-download-thank-you-jsx": () => import("./../../../src/templates/download-thank-you.jsx" /* webpackChunkName: "component---src-templates-download-thank-you-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-pricing-jsx": () => import("./../../../src/templates/pricing.jsx" /* webpackChunkName: "component---src-templates-pricing-jsx" */)
}

